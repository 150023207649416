<template>
  <div class="card">
    <div class="card-header">
      <a-button
        class="mr-2"
        @click="showModalNewLaboratory"
        :disabled="!$auth.isLoggedIn()"
      >
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1 || !$auth.isLoggedIn()"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0 || !$auth.isLoggedIn()"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
      <a-upload class="mr-2" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
      <a-button>
        <upload-outlined></upload-outlined>
        Выберите файл
      </a-button>
    </a-upload>
    <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
    >
      {{ uploading ? 'Загружаю' : 'Начать загрузку' }}
    </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    />

    <a-modal
      width="100"
      v-model:visible="visibleLaboratory"
      title="Лаборатория"
      @ok="handleOkLaboratory"
    >
      <a-form ref="laboratoryForm" :model="LaboratoryFormState">
        <a-form-item label="Наименование">
          <a-input
            v-model:value="LaboratoryFormState.LaboratoryTitle"
            :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { UploadOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import request from 'umi-request';
import { message } from 'ant-design-vue';

export default {
  name: "Laboratory",
    components: {
    UploadOutlined,
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "title", title: "Наименование" },
      ],
      gridData: [],
      visibleLaboratory: false,
      thisLaboratory: [],
      LaboratoryFormState: {
        LaboratoryID: undefined,
        LaboratoryTitle: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
      fileList : [],
      uploading : ref(false),
    };
  },
  methods: {
    async reload() {
      this.gridData = (await axios.get("laboratory")).data;
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisLaboratory = record;
          this.LaboratoryFormState.LaboratoryTitle = record.title;
          this.LaboratoryFormState.LaboratoryID = record.id;
          console.log(this.thislaboratory);
          this.showModalLaboratory();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalLaboratory() {
      this.visibleLaboratory = true;
    },
    showModalNewLaboratory() {
      this.LaboratoryFormState.LaboratoryID = undefined;
      this.LaboratoryFormState.LaboratoryTitle = undefined;
      this.visibleLaboratory = true;
    },
    async handleOkLaboratory() {
      console.log("LaboratoryModal.");
      this.visibleLaboratory = false;
      if (this.LaboratoryFormState.LaboratoryTitle !== undefined) {
        if (this.LaboratoryFormState.LaboratoryID == undefined) {
          try {
            await axios.post("laboratory", {
              title: this.LaboratoryFormState.LaboratoryTitle,
            });
          } finally {
            this.LaboratoryFormState.LaboratoryID = undefined;
            this.LaboratoryFormState.LaboratoryTitle = undefined;
            await this.reload();
          }
        } else {
          console.log("LaboratoryModal.");
          console.log({
            id: this.LaboratoryFormState.LaboratoryID,
            title: this.LaboratoryFormState.LaboratoryTitle,
          });
          try {
            await axios.put("laboratory", {
              id: this.LaboratoryFormState.LaboratoryID,
              title: this.LaboratoryFormState.LaboratoryTitle,
            });
          } finally {
            this.LaboratoryFormState.LaboratoryID = undefined;
            this.LaboratoryFormState.LaboratoryTitle = undefined;
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("laboratory", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
      handleRemove(file){
      const index = this.fileList.value.indexOf(file);
      const newFileList = this.fileList.value.slice();
      newFileList.splice(index, 1);
      this.fileList.value = newFileList;
    },
      beforeUpload(file) {
       this.fileList.push(file)
      return false;
    },
    async handleUpload(){
      const formData = new FormData();
      this.fileList.forEach(file => {
        formData.append('files[]', file);
      });
      this.uploading = true;
      request(axios.defaults.baseURL + '/Upload/Laboratory', {
        method: 'post',
        data: formData,
      })
        .then(() => {
          this.fileList = [];
          this.uploading = false;
          message.success("Загрузка прошла успешно.");
          this.reload();
        })
        .catch(() => {
          this.uploading = false;
          message.error('Ошибка Загрузки.');
        });
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.LaboratoryFormState.LaboratoryID = selectedRows[0].id;
      this.LaboratoryFormState.LaboratoryTitle = selectedRows[0].title;
      this.showModalLaboratory();
    },
  },
  async created() {
    await this.reload();
  },
};
</script>